import React, { useEffect, useState } from "react";
import Header2 from "../Header_next.js";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import OtpSuccess from "./OtpSuccess.js";

const VerifyMobile = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL);
  const [isLoading, setIsLoading] = useState(false);
  const [resendBtnStatus, setResendBtnStatus] = useState(false);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [validateData, setValidateData] = useState(null);
  const [otpValid, setOtpValid] = useState(false);
  const location = useLocation();
  const token = "09|GCzEhGrd1fohcwpr8xlX8Uy06fWHDYV5fvvcM7ZL17fbb59b";

  const owner_id = location?.state?.owner_id;
  const code_value = location?.state?.code_value;
  const number = location?.state?.number;
  const route = location?.state?.route;

  const [senddata, setSendData] = useState({
    code_value: code_value,
    owner_id: owner_id,
  });

  useEffect(() => {
    if (window.sessionStorage.getItem("loggedUserid")) {
      navigate(`/useraccount`, { state: { data: senddata } });
    }
  }, [senddata, navigate]);

  const handleNavigation = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true); // Set loading state to true
      const response = await axios({
        method: "post",
        url: `${url}/validate-otp`,
        data: {
          otp: otp,
          owner_id: JSON.stringify(owner_id),
          code_value: code_value,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.status === 200) {
        window.sessionStorage.setItem(
          "loggedUserid",
          response?.data?.owner_data?.id
        );

        const apiResponse = await axios({
          method: "GET",
          url: `https://api.authkey.io/request?authkey=5231418acaeadbcb&mobile=${number}&country_code=91&template_id=1607100000000308522&pe_id=1601814171272515516&sms=Great job! You've activated the QR code on your sticker. Now, anyone can scan and connect you to report parking issues without knowing your phone number.&sender=SCANQR`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        setValidateData(response?.data);

      } else {
        toast.error("Invalid otp");
        setIsLoading(false); // Set loading state to false
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false); // Set loading state to false
    }
  };

  useEffect(() => {
    if (validateData) {
      if (route?.length > 0) {
        navigate(`/useraccount`, { state: { data: validateData } });
      } else {
        setOtpValid(true);
      }
      setIsLoading(false); // Set loading state to false
    }
  }, [validateData, route, navigate]);

  const handleOtp = (e) => {
    setOtp(e.target.value);
  };

  let lastClickTime = 0;

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setResendBtnStatus(true);
    setIsLoading(true);
    const currentTime = Date.now();

    if (currentTime - lastClickTime < 30000) {
      toast.error("Please wait 30 seconds before clicking again.");
      return;
    }

    try {
      const response = await axios({
        method: "post",
        url: `${url}/resend-otp`,
        params: {
          token: token,
          phone_number: number,
        },
      });

      if (response?.data?.status === 200) {
        toast.success("Otp sent");
      } else {
        toast.error("Error sending otp");
      }
      setResendBtnStatus(false);
      setIsLoading(false)
      lastClickTime = currentTime;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header2 />
      {!otpValid ? (
        <div className="main_div">
          <div className="verify_mobile">
            <p>Verify Your Mobile</p>
            <div className="code_line"></div>
            <p>
              A 4 digit verification code has been sent on your mobile
              <span className="text-style-1"> {number} </span>. Please enter.
            </p>
          </div>

          <input
            type="text"
            className="input_otp"
            name="otp"
            value={otp}
            onChange={handleOtp}
          />

          <button className="otp_btn" disabled={resendBtnStatus} onClick={handleResendOtp}>
           {resendBtnStatus ? "Please wait.." : "Resend OTP" }
          </button>
          <button
            disabled={isLoading}
            className="submit_btn"
            style={{ marginTop: "20px" }}
            onClick={handleNavigation}
          >
            <a>{isLoading ? "Please Wait..." : "SUBMIT"}</a>
          </button>
        </div>
      ) : (
        <div className="main_div">
          <OtpSuccess Data={validateData} />
        </div>
      )}
    </>
  );
};

export default VerifyMobile;
