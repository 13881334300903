
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

const Header = () => {
 const navigate=useNavigate();


const handleNavigation=()=>{
    navigate("/loginmobile")
}




  return (
    <div className="header">
      <div className="header_inner">
        <p className="scan_connect">
          SCAN &<span className="text-style-1"> CO</span>NNEC
          <span className="text-style-2">T</span>
        </p>
     
        <div   className="user_icon" id="user_icon" onClick={() => handleNavigation()}>
        <FaUserCircle />
 
        </div>
       
       
       
      </div>
      
    </div>
  );
};

export default Header;
