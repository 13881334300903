import React, { useEffect, useState } from "react";
import Img1 from "../../assests/img1.png";
import Img2 from "../../assests/img2.png";
import Img3 from "../../assests/img3.png";
import axios from "axios";
import { Modal } from "react-bootstrap";
import FloatingLabelInput from "../Reusable/FloatingLabelInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Contact = ({ ownerdetails, code_value, handleColor }) => {
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL);

  // owner number is the registered number associated with the QR code
  const ownerNumber = ownerdetails?.phone;
  const directCalling = false;

  
  const emergency_phone = ownerdetails?.emergency_phone;
 
  const [id, setId] = useState(null);
  const [selectMessage, setSelectMessage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmergencyModalOpen, setIsEmergencyModalOpen] = useState(false);
  const [isSendOtpModalOpen, setIsSendOtpModalOpen] = useState(false);
  const navigate = useNavigate();
  const [otp, setOtp] = useState({
    opt: "",
  }); // Add state to hold OTP value
  const [btn, setBtn] = useState({status:false, text:""})
 
  const [phonenumber, setPhonenumber] = useState({
    phone_number: "", 
  });

  const phoneRegex = /^[0-9]{10}$/;

  const [selectOption, setOption] = useState([
    "Car Parking",
    "Car Lights On",
    "Car Towed",
    "Car Open",
  ]);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
    handleColor();
  };

  const handleEmergencyModal = () => {
    setIsEmergencyModalOpen(!isEmergencyModalOpen);
    handleColor();
  };

  const handleSendOtpModal = () => {
    setIsSendOtpModalOpen(!isSendOtpModalOpen);
    handleColor();
  };

  useEffect(() => {
    handleCallDetails();
  }, []);

  const handleCallDetails = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${url}/call-temp/create`,
        params: {
          code_value: `${code_value}`,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setId(response?.data?.data?.id);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCall = async () => {
    if (
      phonenumber?.phone_number ||
      phoneRegex.test(phonenumber?.phone_number)
    ){
      const apiResponse = await axios({
        method: "GET",
        url: `https://buzzdial.io/api/callmask.php?cust_no=${phonenumber?.phone_number}&agent_no=${ownerNumber}&agent_name=ScanConnect&did=8037126113&auth=1a9dab9bdf4e2a77&service=start&duration=60`,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
 
      //  alert('owner:- '+ownerNumber +'-' +phonenumber?.phone_number);
      if (apiResponse) {
        if(directCalling == true){ 
           var isiPhone = /iPhone/i.test(navigator.userAgent);
        if (isiPhone) { 
          window.location.href = `tel://${ownerNumber}`;
        } else {
          window.location.href = `tel:${ownerNumber}`;
        }
        }else{ 
        var isiPhone = /iPhone/i.test(navigator.userAgent);
        if (isiPhone) { 
          window.location.href = "tel://08037126113";
        } else {
          window.location.href = "tel:08037126113";
        }

        setTimeout(async () => {
          const apiResponse = await axios({
            method: "GET",
            url: `https://buzzdial.io/api/callmask.php?cust_no=${phonenumber?.phone_number}&agent_no=${ownerNumber}&agent_name=ScanConnect&did=8037126113&auth=1a9dab9bdf4e2a77&service=stop&duration=60`,
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          });
        }, 120000); 

        setIsModalOpen(!isModalOpen);
        handleColor();
       
      } 
      } else {
        toast.error("Error while calling"); 
      }
 
    } else toast.error("Invalid mobile number");
   

  };

  const [location, setLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const sendLocation = async (e) => {
    e.preventDefault();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const apiResponse = await axios({
              method: "GET",
              url: `https://api.authkey.io/request?authkey=5231418acaeadbcb&mobile=${ownerNumber}&country_code=91&sid=13321&carNumber=${ownerdetails.vehicle_number}&latitude=${latitude}&longitude=${longitude}`,
            });
            toast.success(
              "Thanks for sharing the casualty details with the emergency contact"
            );
            setErrorMessage(null);
          } catch (error) {
            console.error("Error sending location:", error);
            setErrorMessage(error.message);
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
          setErrorMessage(error.message);
        }
      );
    } else {
      setErrorMessage("Geolocation is not supported by this browser.");
    }
  };

  const handleFormdata = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPhonenumber({
      ...phonenumber,
      [name]: value,
    });
    if(name === "otp") {
      setOtp(value); // Update OTP state
    }
  };

  const handleMessage = async (e) => {
    e.preventDefault();
    setBtn({status:false, text:""})
    let Url = "";
    // alert(selectMessage)
    
    if (selectMessage == 3) {
      Url = `https://api.authkey.io/request?authkey=5231418acaeadbcb&mobile=${ownerNumber}&country_code=91&sid=13333&carNumber=${ownerdetails.vehicle_number}`;
    }
    //  else if(selectMessage == 4){
       
    // } 
     else {
      Url = `https://api.authkey.io/request?authkey=5231418acaeadbcb&mobile=${ownerNumber}&country_code=91&sid=13334&carNumber=${
        ownerdetails.vehicle_number
      }&alertType=${
        selectOption[selectMessage - 1]
      }&companyName=Team SCANCONNECT`;
    }

    try {
      const apiResponse = await axios({
        method: "GET",
        url: Url,
      });
     

      
      // if(selectMessage == 3 ){
      //   navigate("/messagebox?option=3");
      // }
      // if(selectMessage == 4){
      //   navigate("/messagebox?option=4");
      // }
      navigate("/messagebox");
    } catch (error) {
      console.log("error:", error);
    } finally {
    }
  };


  const handleSendOtpToPhone = async (e) => { 
    if (phonenumber?.phone_number || phoneRegex.test(phonenumber?.phone_number)) {
      setIsEmergencyModalOpen(false); // Close the emergency modal
      setIsSendOtpModalOpen(true); // Open the OTP modal 
      // setPhonenumber({ phone_number: "" }); // Clear the phone number field
      try {
        const response = await axios({
          method: "GET",
          url: `${url}/otp-for-emergency`,
          params: { 
            phone: phonenumber?.phone_number,
          },
        }); 
      } catch (error) {
        console.error("Error:", error);
      } 
    } else{ 
      toast.error("Invalid mobile number");
    } 
  };

  const handleVerifyOtpModal = async (e) => { 
    if (phonenumber?.otp) { 
       try { 
        const response = await axios({
          method: "GET",
          url: `${url}/verify-otp-for-emergency`,
          params: {
            phone:phonenumber?.phone_number,
            otp:phonenumber?.otp
          },
        });
 
  
      if(response?.data?.message === "user_verified"){
        setIsSendOtpModalOpen(false);
        handleColor();
        var isiPhone = /iPhone/i.test(navigator.userAgent);
        if (isiPhone) { 
          window.location.href = `tel://${emergency_phone}`;
        } else {
          window.location.href = `tel://${emergency_phone}`;
        }
      }else if(response?.data?.message === "wrong_otp"){
        toast.error("You have entered wrong OTP");
      }else{
        console.log(response);
        toast.error("Something went wrong please try again !");
      }
      }catch (error){
        console.error("Error:", error);
      }
    } else{ 
      toast.error("Invalid mobile number");
    } 
  }

  return (
    <>
      <p className="contact">Contact Vehicle Owner</p>
      <div className="contact_line"></div>
      <p className="car_id">{ownerdetails?.vehicle_number}</p>
      <p className="para_1">
        Please select a reason why do you want to contact the owner.
      </p>
      <div className="contact_options">
        <p className="checkbox_1" htmlFor="" onClick={() => setSelectMessage(1)}>
          The car is not parked properly
        </p>
        <input type="radio" className="Ellipse_5" value={1} onChange={() => setSelectMessage(1)} checked={selectMessage == 1 && true}/>
      </div>

      <div className="contact_options">
        <p className="checkbox_1" htmlFor="" onClick={() => setSelectMessage(2)} >
          The lights of this car is on
        </p>
        <input type="radio" className="Ellipse_5" value={2} onChange={() => setSelectMessage(2)} checked={selectMessage == 2 && true} />
      </div>

      <div className="contact_options">
        <p className="checkbox_1" htmlFor="" onClick={() => setSelectMessage(3)} >
          The car is getting towed
        </p>
        <input type="radio" className="Ellipse_5" value={3} onChange={() => setSelectMessage(3)} checked={selectMessage == 3 && true}/>
      </div>

      <div className="contact_options">
        <p className="checkbox_1" htmlFor="" onClick={() => setSelectMessage(4)}>
          The window or car is open
        </p>
        <input
          type="radio"
          className="Ellipse_5"
          value={4}
          onChange={() => setSelectMessage(4)}
          checked={selectMessage == 4 && true}
        />
      </div>

      <div className="btn_section">
        <button className="btn" onClick={() => handleModal()}>
          <span>CALL</span>
        </button>
        <button className="btn" onClick={(e) => { handleMessage(e);setBtn({status:true, text:"Sending message.."}) }} disabled={btn.status}>
          <span>{btn.status ? btn.text: "SEND MESSAGE"} </span>
        </button>
      </div>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header style={{ justifyContent: "center" }}>
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="call_main">
                <div>
                  <p>
                    Please provide your phone number so that we can setup a call
                    with vehicle owner
                  </p>
                  <div className="call_section">
                    <FloatingLabelInput
                      id="phone_number"
                      label="ENTER YOUR PHONE NUMBER"
                      type="number"
                      name="phone_number"
                      value={phonenumber?.phone_number}
                      handleFormdata={handleFormdata}
                      requiredval={true}
                    />
                    <button className="call_btn" onClick={() => handleCall()}>
                      CALL
                    </button>
                    <p className="line_break"></p>
                    <button className="cancel_btn" onClick={() => handleModal()}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
      </Modal>


      <Modal show={isEmergencyModalOpen} onHide={() => setIsEmergencyModalOpen(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="call_main">
            <div>
              <p>
                Please provide your phone number to send OTP
              </p>
              <div className="call_section">
                <FloatingLabelInput
                  id="phone_number"
                  label="ENTER YOUR PHONE NUMBER"
                  type="number"
                  name="phone_number"
                  value={phonenumber?.phone_number}
                  handleFormdata={handleFormdata}
                  requiredval={true}
                />
                <button className="call_btn" onClick={() => handleSendOtpToPhone()}>
                  Send OTP
                </button>
                <p className="line_break"></p>
                <button className="cancel_btn" onClick={() => handleEmergencyModal()}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>


      <Modal
        show={isSendOtpModalOpen}
        onHide={() => setIsSendOtpModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="call_main">
            <div>
              <p>
               Enter OTP and verify you phone number
              </p>
              <div className="call_section">
                <FloatingLabelInput
                  id="otp"
                  label="ENTER OTP"
                  type="text"
                  name="otp"
                  value={otp?.otp}
                  handleFormdata={handleFormdata}
                  requiredval={true}
                />
                <button className="call_btn" onClick={() => handleVerifyOtpModal()}>
                  Verify
                </button>
                <p className="line_break"></p>
                <button className="cancel_btn" onClick={() => handleSendOtpModal()}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <div className="emergency_message">
        <div className="Emergency_Connect">
          <p>Emergency Contact</p>
          <img
            src={Img1}
            alt=""
            className=".Image-1"
            srcSet={`${Img1} 1x, ${Img2} 2x, ${Img3} 3x`}
          />
        </div>
        <p className="para_2">
          'URGENT. The vehicle owner has met with causality on the road. Please
          send emergency support'
        </p>
        <button className="message_btn" onClick={(e) => handleEmergencyModal(e)}>
        {/* <button className="message_btn" onClick={(e) => sendLocation(e)}> */}
         Call Emergency Number
        </button>
      </div>
    </>
  );
}; 
export default Contact;
