import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import FloatingLabelInput from "../Reusable/FloatingLabelInput";
import Contact from "./Contact";
import axios from "axios";
import { toast } from "react-toastify";
import Error from "../Reusable/Error";
import Home from "./Home";
import Loading from "../Reusable/Loading";
import InactiveCode from "./InactiveCode";


const ActivateCode = ({ handleColor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL);
  const [code_value, setCodevalue] = useState(null);
  const [codeStatus, setCodestatus] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(false);
  const vehicleNoRegex = /^[A-Za-z0-9\s-]{1,20}$/;
  const nameRegex = /^[A-Za-z\s]{1,50}$/;
  const phoneRegex = /^[0-9]{10}$/;
  const navigate = useNavigate();

  useEffect(() => {
    const data = window.location.href;
    const data1 = data.split("=");
    if (data1.length > 1) {
      setCodevalue(data1[data1.length - 1]);
    }

  }, []);


  useEffect(()=>{
    if(window.sessionStorage.getItem("HistoryFlag")==1){
      console.log(window.sessionStorage.getItem("HistoryFlag"))
      // navigate("/");
    }
  },[])


  const [ownerStatus, setOwnerStatus] = useState(null);
  const [ownerdetails, setOwnerdetails] = useState(null);
  const [formdata, setFormdata] = useState({
    vehicle_no: "",
    owner_name: "",
    phone_number: "",
    emergency_number: "",
    code_value: code_value,
    token: "09|GCzEhGrd1fohcwpr8xlX8Uy06fWHDYV5fvvcM7ZL17fbb59b",
  });



  const handleNavigation = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true
    formdata.code_value = code_value;

    const errors = [];

    if (!formdata.vehicle_no || !vehicleNoRegex.test(formdata.vehicle_no)) {
      errors.push("Invalid vehicle number.");
      setIsLoading(false);
    }

    if (!formdata.owner_name || !nameRegex.test(formdata.owner_name)) {
      errors.push("Invalid owner name.");
      setIsLoading(false);
    }

    if (!formdata.phone_number || !phoneRegex.test(formdata.phone_number)) {
      errors.push("Invalid phone number.");
      setIsLoading(false);
    }

    if (
      !formdata.emergency_number ||
      !phoneRegex.test(formdata.emergency_number)
    ) {
      errors.push("Invalid emergency number.");
      setIsLoading(false);
    }

    if (errors.length > 0) {
      errors.map((error, i) => {
        i == 0 && toast.error(error);
        setIsLoading(false);
      });

      return;
    }

    try {
      const response = await axios({
        method: "post",
        url: `${url}/create-owner`,
        data: JSON.stringify(formdata),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.status === 200) {
        if(response?.data?.message==="user_already_exist"){
          setIsLoading(false); // Set loading state to true
          toast.error("Phone number is already registered");
        }

        else{
          navigate("/verifymobile", {
            state: {
              owner_id: response?.data?.owner_id,
              code_value: code_value,
              number: formdata.phone_number,
              route:""
            },
          });
        }
       
      }

      else{
        setIsLoading(false); // Set loading state to true
        toast.error(response?.data?.error)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    code_value !== null && handleValidation();
  }, [code_value]);

  const handleFormdata = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormdata({
      ...formdata,
      [name]: value.toUpperCase(),
    });
  };

  const handleValidation = async () => {
    setloading(true)
    try {
      const apiResponse = await fetch(
        `${url}/get-owner-detail?code_value=${code_value}&token=09|GCzEhGrd1fohcwpr8xlX8Uy06fWHDYV5fvvcM7ZL17fbb59b`
      );

      const data = await apiResponse.json();
      setCodestatus(data?.code_status);
      setOwnerStatus(data?.owner_status);
      if (data?.owner_status == 1 && data?.code_status == 2) {
        setOwnerdetails(data?.owner_detail);
      } else if (data?.code_status == 4) {
        toast.error(data?.message);
      } else if (data?.code_status == 3) {
        toast.error(data?.message);
      } else if (data?.code_status == 1) {
      } else {
        if (code_value != ""){
          setError(true);
          toast.error("Code not Found");
        }
      }

      setloading(false)
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
  

  return (
    <>
      {loading ? (
        <Loading />
      ) : code_value == null || error ? (
        code_value == null ? (
          <Home />
        ) : (
          <Error />
        )
      ) : (
        codeStatus && (
          <>
            <Header />
            <div className="main_div">
              {codeStatus === 1 ? (
                <>
                  <div className="activate_code">
                    <p>Activate The Code</p>
                    <div className="code_line"></div>
                    <p>
                      Welcome,
                      <br />
                      Now get informed about parking concerns. Let’s enroll.
                    </p>
                  </div>
                  <div className="input_box">
                    {[
                      { id: "vehiclenumber", label: "Enter Vehicle Number", type: "text", name: "vehicle_no" },
                      { id: "name", label: "Enter Name", type: "text", name: "owner_name" },
                      { id: "pnumber", label: "Enter Phone Number", type: "number", name: "phone_number", message: "(One time verification code will be sent to this number)" },
                      { id: "emergencynumber", label: "Enter Emergency Contact", type: "number", name: "emergency_number", message: "(This number will be visible to callers and used only in emergencies like accidents)" }
                    ].map(input => (
                      <div key={input.id}>
                        <FloatingLabelInput
                          id={input.id}
                          label={input.label}
                          type={input.type}
                          name={input.name}
                          handleFormdata={handleFormdata}
                          value={formdata[input.name]}
                        />
                        {input.message && <p className="span_field">{input.message}</p>}
                      </div>
                    ))} 
                  </div>
                  <button className="submit_btn" onClick={(e) => handleNavigation(e)} disabled={isLoading}>{isLoading ? "Please Wait..." : "SUBMIT"}</button>
                </>
              ) : 
              codeStatus === 3 ? (
                 <InactiveCode code_status={3}/>


              ) : codeStatus === 4 ? (
               <InactiveCode code_status={4}/>
              ) :
              (
                <Contact ownerdetails={ownerdetails} code_value={code_value} handleColor={handleColor}/>
              )}
            </div>
          </>
        )
      )}
   
    </>
  );
  
};

export default ActivateCode;
