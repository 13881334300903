import React, { useState } from "react";

function FloatingLabelInput({
  id,
  label,
  type,
  name,
  handleFormdata,
  value,
  requiredval,
}) {
  const [focused, setFocused] = useState(false);
  const [value1, setValue] = useState(value);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (!value) {
      setFocused(false);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    handleFormdata(e);
  };

  const style1 = {
    marginBottom: "20px",
  };

  const style2 = {
    marginBottom: "5px",
  };

  return (
    <div
      className="input_box_inner"
      style={id == "pnumber" || id == "emergencynumber" ? style2 : style1}
    >
      <input
      name={name}
      type={type}
      id={id}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      className={focused ? 'focused' : ''}
      {...(requiredval ? { required: true } : {})}
    />

      <label htmlFor={id} className={focused || value1 ? "active" : ""}>
        {label}
      </label>
    </div>
  );
}

export default FloatingLabelInput;
