import React, { useState } from "react";
import Header2 from "../Header_next";
import FloatingLabelInput from "../Reusable/FloatingLabelInput";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const EditProfile = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL);
  const navigate = useNavigate();
  const location = useLocation();
  const [ownerdata, setOwnerdata] = useState(location?.state?.ownerData);
  const { code_value, owner_id } = location?.state;
  const emergencyPhoneRegex = /^[0-9]{10}$/;
  const nameRegex = /^[A-Za-z\s]{1,50}$/;

  const [updateddata, setUpdateddata] = useState({
    name: ownerdata?.name,
    emergency_contact: ownerdata?.emergency_phone,
    address: ownerdata?.address_line || "",
    society: ownerdata?.block_society || "",
    parking_slot: ownerdata?.parking_slot || "",
    city: ownerdata?.city || "",
    token: "09|GCzEhGrd1fohcwpr8xlX8Uy06fWHDYV5fvvcM7ZL17fbb59b",
    phone: ownerdata?.phone,
  });

  const [senddata, setSendData] = useState({
    code_value: code_value,
    owner_id: owner_id,
  });

  const handleNavigation = async (e) => {
    e.preventDefault();
    {
      if (!emergencyPhoneRegex.test(updateddata.emergency_contact)) {
        toast.error(
          "Invalid emergency number i.e number should be of 10 digit"
        );
      } else if (!updateddata.name || !nameRegex.test(updateddata.name)) {
        toast.error("Name field can't be empty");
      } else {
        try {
          const response = await axios({
            method: "post",
            url: `${url}/update-profile`,
            data: updateddata,
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response?.data?.status == 200) {
            toast.success("Data updated successfully");
            setTimeout(() => {
              navigate(`/useraccount`, { state: { data: senddata } });
            }, 3000);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  };

  const handleFormdata = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUpdateddata({
      ...updateddata,
      [name]: value,
    });
  };

  return (
    <>
      <Header2 />
      <div className="main_div">
        <div className="edit_profile">
          <p className="Edit-Profile">Edit Profile</p>
          <div className="code_line"></div>
        </div>

        <div className="input_box">
          <FloatingLabelInput
            id="name"
            label="Name"
            type="text"
            name="name"
            value={updateddata?.name}
            requiredval={true}
            handleFormdata={handleFormdata}
          />
          <FloatingLabelInput
            id="emergency_contact"
            label="Emergency Contact"
            type="number"
            name="emergency_contact"
            value={updateddata?.emergency_contact}
            requiredval={true}
            handleFormdata={handleFormdata}
          />
          <FloatingLabelInput
            id="address"
            label="Address(Optional)"
            type="text"
            name="address"
            value={updateddata?.address}
            handleFormdata={handleFormdata}
            requiredval={false}
          />
          <FloatingLabelInput
            id="city"
            label="City(Optional)"
            type="text"
            value={updateddata?.city}
            name="city"
            requiredval={false}
            handleFormdata={handleFormdata}
          />
          <FloatingLabelInput
            id="society"
            label="Block/Society Name(Optional)"
            type="text"
            name="society"
            value={updateddata?.society}
            handleFormdata={handleFormdata}
            requiredval={false}
          />
          <FloatingLabelInput
            id="parking_slot"
            label="Parking Slot If Any(Optional)"
            type="text"
            name="parking_slot"
            value={updateddata?.parking_slot}
            handleFormdata={handleFormdata}
            requiredval={false}
          />
        </div>

        <button className="update_btn" onClick={(e) => handleNavigation(e)}>
          <span className="Update">UPDATE</span>
        </button>
      </div>
    </>
  );
};

export default EditProfile;
