import React from 'react';
import {useNavigate } from 'react-router-dom';

const OtpSuccess = ({Data}) => {
    const navigate=useNavigate();
    const handleLogout = () => {
        window.sessionStorage.removeItem("loggedUserid");
        navigate("/loginmobile");
      };

   const handleNavigate=()=>{
    navigate(`/useraccount`,{ state: {data:Data} });
   }

  return (
    <div className="otp_success">
       
       <h1>Congratulations!</h1>
           <h2>Your QR code is  <br /> successfully activated</h2> 
        <div className="btn_section">
       
        <button className="btn" onClick={()=> handleNavigate()} title='Go To User Account'>
          <span>ACCOUNT</span>
        </button>
        <button className="btn"   onClick={() => handleLogout()} title='Go To Login Page'>
          <span>CLOSE</span>
        </button>
      </div>
       
      

    </div>
  );
}

export default OtpSuccess;
