import React, { useState } from "react";
import Header2 from "../Header_next";
import Img1 from "../../assests/paytm.png";
import Img2 from "../../assests/paytm2.png";
import Img3 from "../../assests/paytm3.png";
import gpay from "../../assests/gpay.png";
import gpay2 from "../../assests/gpay2.png";
import gpay3 from "../../assests/gpay3.png";
import bpay from "../../assests/bpay.png";
import bpay2 from "../../assests/bpay2.png";
import bpay3 from "../../assests/bpay3.png";
import Qr from "../../assests/qr.png";
import Qr1 from "../../assests/qr.png";
import Qr2 from "../../assests/qr.png";
import Qrnext from "../../assests/qrnext.png";
import Qrnext2 from "../../assests/qrnext2.png";
import QRCode from "qrcode.react";
import axios from "axios";
import { toast } from "react-toastify";

const Masking = ({ handleColor }) => {
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL);
  const qrValue = "Rs. 200";
  const [qrcode, setQrcode] = useState(false);

  const handleQr = async (e) => {
    e.preventDefault();
    setQrcode(!qrcode);
    handleColor();

    try {
      const response = await axios({
        method: "post",
        url: `${url}/send-recharge-email`,
      });
      if (response) toast.success("Email Sent");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <Header2 />
      <div className="main_div">
        <div className="masking_page">
          <p className="Enable_masking">Enable Masking</p>
          <div className="code_line"></div>
        </div>

        <p className="payment_message">
          Please recharge with
          <span className="text-style-1"> INR 200 </span>
          to enable your call masking. Currently your security is compromised
          and your phone number is visible on scan
        </p>

        <div className="payment_input">
          <p className="amount_text">Recharge Amount</p>
          <div className="amount">
            <p className="Rs">Rs. 200</p>
          </div>
        </div>

        <div className="payment_options">
          <p className="amount_text">Payment Options</p>

          <div className="upi_section">
            <div className="upi_options">
              <div className="pay">
                <img
                  src={Img1}
                  srcSet={`${Img1} 1x, ${Img2} 2x, ${Img3} 3x`}
                  alt="img"
                />
                <p>Paytm UPI</p>
              </div>

              <div className="pay">
                <img
                  src={gpay}
                  srcSet={`${gpay} 1x, ${gpay2} 2x, ${gpay3} 3x`}
                  alt="img"
                />
                <p>Google Pay</p>
              </div>

              <div className="pay">
                <img
                  src={bpay}
                  srcSet={`${bpay} 1x, ${bpay2} 2x, ${bpay3} 3x`}
                  alt="img"
                />
                <p>PhonePe</p>
              </div>
            </div>
            <p className="line_break"></p>

            <div className="scan_QR" onClick={(e) => handleQr(e)}>
              <img
                src={Qr}
                srcSet={`${Qr} 1x, ${Qr1} 2x, ${Qr2} 3x`}
                alt="img"
              />
              <p>Scan QR code to pay ₹ 200</p>
              <img
                src={Qrnext}
                srcSet={`${Qrnext} 1x, ${Qrnext2} 2x`}
                alt="img"
                className="next_img"
              />
            </div>
          </div>
        </div>

        {qrcode ? (
          <div className="qr_code">
            <QRCode value={qrValue} className="qrCode_img" />
            <p className="confirm">Confirmation</p>
            <p className="gm">
              After successful payment, your call masking will get activated
              within the next 48 hours.
            </p>
            <p className="line_break"></p>
            <button className="close_btn" onClick={(e) => handleQr(e)}>
              Close
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Masking;
