import Qr from "../../assests/stickerimg3.jpg";
import Sticker from "../../assests/stickerimg2.png";
import CarsImg from "../../assests/carsimg.png";
import BikesImg from "../../assests/bikes.png";
import { MdMobileOff,MdMobileScreenShare,MdOutlineQrCodeScanner } from "react-icons/md";
import { BsSignNoParking } from "react-icons/bs";
import { IoCallOutline } from "react-icons/io5";
import {
  FaPhoenixFramework,
  FaCommentAlt,
  FaMailBulk,
  FaYoutube,
  FaInstagram,
  FaCar 
} from "react-icons/fa";
import { BiLogoFacebook } from "react-icons/bi";
import { IoIosCall } from "react-icons/io";
import { useEffect } from "react";

const Home = () => {


  useEffect(()=>{
    const data = window.location.href;
    const data1 = data.split("/");
    if (data1.length == 0) {
      window.sessionStorage.removeItem("HistoryFlag");
    }
    
  },[])

  return (
    <div style={{ width: "100%" }}>
      <section className="banner">
        <div className="banner_inside">
          <div>
            <h1>
              Your QR Code!
              <br />
              SCAN & CONNECT
            </h1>
          </div>
          <div></div>
        </div>
      </section>

      <section style={{ background: "white" }}>
        <div className="container">
          <div className="row banner_box">
            <div>
              <img src={Qr} alt="img" />
            </div>

            <div>
              <h3>
                Switching from placing your mobile number on your car requires
                effort, <br />
                <span>consider using a QR code</span>
              </h3>
              <p>
                Say goodbye to the hassle of displaying your mobile number on
                your car dashboard. Embrace simplicity with our QR Code Sticker
                solution, enabling effortless contact with just a scan. <br />{" "}
                <span>
                  {" "}
                  YOUR PRIVACY IS PROTECTED | FREE DELIVERY | LIFE TIME SERVICE
                </span>
              </p>

              <div className="sticker_price">
                {" "}
                <div className="banner_icons">
                <BsSignNoParking /> Wrong Parking  &nbsp; &nbsp; &nbsp;  <IoCallOutline /> Emergency Contact <br />
               
                </div>
              
                Any issue with the vehicle, Scan the QR
              </div>
            
            </div>
          </div>
        </div>
      </section>

      {/* ---------------------------------------------Quote Section ---------------------------------------*/}

      <div className="quote">
        {" "}
        <p>*YOUR PHONE NUMBER WILL NOT BE SHOWN WHILE CALLING</p>
      </div>

      {/* ---------------------------------------Banner Category--------------------------------------- */}

      <section className="banner_category">
        <div className="boy-text-img ">
          <img src={Sticker} alt="img" className="boy_img" />
        </div>
        <div className="boy-text-node">
          <p>
            If You're Behind the Wheel 🚗, <br /> Having Your own Personalized
            QR code <br /> is an Absolute Game-Changer!
          </p>
        </div>
      </section>

      {/* ---------------------------------Features Section -------------------------------------------*/}

      <section className="feature_section">
        <h2>HOW IT WORKS?</h2>
        <div className="feature_box">
          <p><span><FaCar /></span>Apply sticker inside car windshield</p>

          <p><span><MdOutlineQrCodeScanner /></span>Scan it for first time for initial setup</p>

          <p><span><MdMobileScreenShare /></span>Enter your mobile and vehicle number</p>
          <p><span><BsSignNoParking /></span>If parked wrongly, anyone can scan and call you</p>

          <p><span><MdMobileOff /></span> Your mobile number will not be shown to the caller</p>
        </div>
      </section>

      {/* --------------------------Youtube Section ------------------------------------------------*/}

      <section className="youtube_section">
        <p>
          WHY ITS <br /> <span>IMPORTANT?</span>
        </p>

        <h2>
          This sticker on your car lets people report wrong parking without
          knowing your phone number
        </h2>
      </section>

      {/*---------------------------- Category Selection -------------------------------------------*/}
      <section
        style={{ background: "white", textAlign: "center", color: "white" }}
      >
        <div className="category_section">
          <h2>Choose the category for which you want QR for</h2>
          <div>
            <img src={CarsImg} alt="img" />
            <img src={BikesImg} alt="img" />
          </div>
        </div>
      </section>

      {/* ----------------------------------------About Us Section ------------------------------------------*/}

      <section className="about_us">
        <div className="about_us_content">
          <h2>
            HOW WE BEGAN SCAN & CONNECT <br />{" "}
            <p>
              "Combining these skills and attributes can create a synergistic
              partnership where one person's strengths complement the other's
              weaknesses, leading to a more well-rounded and successful
              endeavor.!"
            </p>
          </h2>
        </div>
      </section>

      {/* -------------------------------------------------------Footer Section------------------------------------------ */}

      <footer className="footer">
        <div className="footer_links">
          <div>
            <h2>CONTACT</h2>
            <div className="footer_options">
              <p>
                <IoIosCall /> <span>+91 7042748916</span>
              </p>
              <p>
                {" "}
                <FaMailBulk />
                <span>bigladdertechnologies@gmail.com</span>
              </p>
              <p className="share-option">
                <span>
                  <BiLogoFacebook className="fb" />
                </span>
                <span>
                  <FaInstagram className="insta" />
                </span>
                <span>
                  <FaYoutube className="youtube" />
                </span>
              </p>
            </div>
          </div>
          <div>
            <h2>TERMS</h2>
            <div className="footer_options">
              <p>Terms & conditions</p>
              <p>Privacy & Copyrights</p>
            </div>
          </div>

          <div>
            <h2>SCAN & CONNECT</h2>
            <div className="footer_options">
              <p>
                {" "}
                <FaCommentAlt /> <span>Review Us !</span>
              </p>
              <p>
                {" "}
                <FaPhoenixFramework />
                <span>Become our DISTRIBUTOR</span>
              </p>
            </div>
          </div>
        </div>

        <div className="copyright_section">
          <p>
            2024 @ <span> Owned & Managed by ACMATICS</span> All rights reserved
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
