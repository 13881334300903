import React from 'react';

const Loading = () => {
  return (
    <div className='loading'>
               <div className='loading-top'>
    </div>
    </div>
   
  );
}

export default Loading;
