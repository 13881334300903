import { useSearchParams } from 'react-router-dom';
import Header from '../Header';
import { BiCommentX } from "react-icons/bi";

const InactiveCode = ({code_status}) => {
  const [searchParams] = useSearchParams();
  const selectMessage = searchParams.get('option');
  window.sessionStorage.setItem("HistoryFlag", 1);

  return (
    <> 
      {code_status === 3 ? 
        <div className="otp_success">
          <div className='message_box'>
            <BiCommentX />
          </div>

          <h3 style={{ color: 'red' }}>QR Code deactivated</h3>
          <h4 style={{ padding: "20px 40px" }}>The QR code is currently deactivated by the vehicle owner.</h4>
        </div>

        : code_status === 4 ?
          <div className="otp_success">
            <div className='message_box'>
              <BiCommentX />
            </div>

            <h3 style={{ color: 'red' }}>QR Code Deactivated</h3>
            <h4 style={{ padding: "20px 40px" }}>The QR code is currently deactivated by the seller. Please contact the shop from whom you have purchased the sticker.</h4>
          </div> :

         null
      }
    </>
  );
}

export default InactiveCode;
