import React from 'react';

const Error = () => {
  return (
<div id="notfound">
    <div class="notfound">
      <div class="notfound-404">
        <h3>Oops! Code not found</h3>
        <h1 className='error_code'><span>4</span><span>0</span><span>4</span></h1>
      </div>
      <h2>we are sorry, but the code you requested was not found</h2>
    </div>
  </div>
  );
}

export default Error;
