import React, { useEffect, useState } from "react";
import Header2 from "../Header_next";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../Reusable/Loading";

const UserAccount = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL);
  const location = useLocation();
  const [ownerdata, setOwnerdata] = useState(null);
  const [codedata, setCodedata] = useState(null);
  const [value, setValue] = useState(null);
  const [logindata, setLoginData] = useState(location?.state?.data);
  const [togglebtn, setTogglebtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { code_value, owner_id } = logindata;
  const navigate = useNavigate();

  const handleNavigation = (redirectUrl) => {
    ownerdata &&
      navigate(redirectUrl, {
        state: {
          ownerData: ownerdata,
          code_value: code_value,
          owner_id: owner_id,
        },
      });
  };

  const handleValue = async () => {
    try {
      setLoading(true);
      const response = await axios({
        method: "post",
        url: `${url}/update-code-status`,
        data: {
          token: "09|GCzEhGrd1fohcwpr8xlX8Uy06fWHDYV5fvvcM7ZL17fbb59b",
          code_value: code_value,
          status: togglebtn ? 'inactive' : 'active', // Toggle the status
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
 
      if (response?.data?.status === 200) {
        setTogglebtn(!togglebtn); // Toggle the button state if API call is successful
      } else {
        alert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("loggedUserid")) {
      getOwnerdata();
    } else {
      navigate(`/?code=${code_value}`);
    }
  }, []);

  const getOwnerdata = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: `${url}/code-setting`,
        data: {
          token: "09|GCzEhGrd1fohcwpr8xlX8Uy06fWHDYV5fvvcM7ZL17fbb59b",
          owner_id: owner_id,
          code_value: code_value,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.status == 200) {
        setCodedata(response?.data?.code_data);
        setOwnerdata(response?.data?.owner_data);
        setValue(response?.data?.code_data?.call_minutes);

        // Set the toggle button state based on code_status_type_id
        setTogglebtn(response?.data?.code_data?.code_status_type_id === 2);

        setLoading(false);
      } else {
        alert(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Header2 />
      {loading ? (
        <Loading />
      ) : (
        <div className="main_div">
          <div className="code_setting">
            <p className="Code-Settings">User Account</p>
            <div className="code_line"></div>
          </div>

          <div className="profile_data">
            <span>
              Welcome,
              <span className="text-style-1">
                {" "}
                {ownerdata?.name.toUpperCase()}
              </span>{" "}
              <br />
            </span>
            <span>Registered Phone - {ownerdata?.phone}</span>
            <p className="line_break"></p>
            <p className="edit">
              <a onClick={() => handleNavigation("/editprofile")}>EDIT PROFILE</a>
            </p>
          </div>

          <div className="status_box">
            <div className="status_top">
              <span className="Status">Status</span>
              {!togglebtn ? (
                <button className="status_btn" onClick={() => handleValue()}>
                  <span className="InActive">Inactive</span>
                  <div className="inactive_btn"></div>
                </button>
              ) : (
                <button className="status_btn_1" onClick={() => handleValue()}>
                  <div className="active_btn"></div>
                  <span className="Active">Active</span>
                </button>
              )}
            </div>

            {value > 0 ? null : (
              <>
                <p className="line_break"></p>
                <p className="mask_para">
                  Please recharge with INR 200 to enable your call masking.
                  Currently your security is compromised and your phone number is
                  visible on scan.
                </p>
                <button
                  className="enable_btn"
                  onClick={() => handleNavigation("/enablemasking")}
                >
                  <a className="enable">ENABLE MASKING</a>
                </button>
              </>
            )}

            <p className="line_break"></p>

            <div className="email_support">
              <p className="text-style-1">For Support Email us at:</p>
              care.scanconnect@gmail.com
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserAccount;
