import { useSearchParams } from 'react-router-dom';
import Header from '../Header';
import { BiSolidMessageCheck } from "react-icons/bi";

const MessageBox = () => {
  const [searchParams] = useSearchParams();
  const selectMessage = searchParams.get('option');
  window.sessionStorage.setItem("HistoryFlag", 1);

  return (
    <>

      <Header />
      {selectMessage === 3 ?

        <div className="otp_success">
          <div className='message_box'>
            <BiSolidMessageCheck />
          </div>

          <h3 style={{ color: 'red' }}>QR Code deactivated</h3>
          <h4 style={{ padding: "20px 40px" }}>The QR code is currently deactivated by the vehicle owner.</h4>
        </div>

        : selectMessage === 4 ?
          <div className="otp_success">
            <div className='message_box'>
              <BiSolidMessageCheck />
            </div>

            <h3 style={{ color: 'red' }}>QR Code Deactivated</h3>
            <h4 style={{ padding: "20px 40px" }}>The QR code is currently deactivated by the seller. Please contact the shop from whom you have purchased the sticker.</h4>
          </div> :

          <div className="otp_success">
            <div className='message_box'>
              <BiSolidMessageCheck />
            </div>

            <h3 style={{ color: 'green' }}>Message Sent Successfully</h3>
            <h4 style={{ padding: "20px 40px" }}>We have sent your message to the car owner. He will be coming to the car location in a few minutes. Thanks for your patience.</h4>
          </div>
      }
    </>
  );
}

export default MessageBox;
