import React, { useEffect, useRef, useState } from "react";
import { LuLogOut } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

import { IoIosArrowBack } from "react-icons/io";

const Header_next = () => {
  const [logbtn, setLogbtn] = useState(true);
  const [loginstate, setLoginstate] = useState(false);
  const navigate = useNavigate();
  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    setTimeout(()=>{
      if (window.sessionStorage.getItem("loggedUserid")) {
        setLoginstate(true);
      }
    },3000)
  
  }, []);

  const style1 = {
    display: "none",
    transform: "translateX(50px)",
  };

  const style2 = {
    display: "flex",
    alignItems: "center",
    transform: "translateX(-38px)",
    width: "63px",
  };

  const handleLogout = () => {
    window.sessionStorage.removeItem("loggedUserid");
    navigate("/loginmobile");
  };

  const handleNavigation = () => {
    navigate("/loginmobile");
  };


  const dropdownRef=useRef();

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (!event.target.closest("#user_icon")) {
        setLogbtn(true);
      }
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);



  return (
    <div className="header">
      <div className="header_inner">
        <div className="user_icon" onClick={() => handleBack()} style={{marginRight:"10px"}}>
        <IoIosArrowBack/>
        </div>

        <p className="scan_connect_2">
          SCAN &<span className="text-style-1"> CO</span>NNEC
          <span className="text-style-2">T</span>
        </p>

        <div onClick={() => setLogbtn(!logbtn)} className="user_icon" ref={dropdownRef} id="user_icon">
          <FaUserCircle />

          {loginstate ? (
            <button
              className="log_btn"
              style={logbtn ? style1 : style2}
              onClick={() => handleLogout()}
            >
              <LuLogOut />
              <span>Logout</span>
            </button>
          ) : (
            <button
              className="log_btn"
              style={
                logbtn ? { 
                  ...style1,
                 
                  transform: "translateX(-31px)" 
              } : { 
                  ...style2,
                  width: "60px",
                  transform: "translateX(-31px)"
              }
              }
              onClick={() => handleNavigation()}
            >
              <LuLogOut /> <span>Login </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header_next;
