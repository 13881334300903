import ActivateCode from "./components/Dashboard/ActivateCode";
import VerifyMobile from "./components/Dashboard/VerifyMobile";
import LoginMobile from "./components/Dashboard/LoginMobile";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserAccount from "./components/Dashboard/UserAccount";
import EditProfile from "./components/Dashboard/EditProfile";
import Masking from "./components/Dashboard/Masking";

import "./App.css";
import "./css/activatecode.css";
import "./css/contact.css";
import "./css/verifymobile.css";
import "./css/loginmobile.css";
import "./css/useraccount.css";
import "./css/editprofile.css";
import "./css/masking.css";
import "./css/home.css"
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./components/Reusable/404";
import MessageBox from "./components/Dashboard/MessageBox";

function App() {
  const [color, setColor] = useState(false);
  const handleColor = () => {
    setColor(!color);
  };
  return (
    <>
      <div
        style={{
          background: `${color ? "rgba(35, 51, 50, 0.67)" : "#f8f8ff"}`,
          display: `${color ? "block" : "none"}`,
          width: "100vw",
          height: "100vh",
          position: "absolute",
        }}
      ></div>
      <div className="main_container">
        <ToastContainer />
        <Routes>
          <Route path="/" element={<ActivateCode handleColor={handleColor} />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route
            path="/enablemasking"
            element={<Masking handleColor={handleColor} />}
          />
          <Route path="/verifymobile" element={<VerifyMobile handleColor={handleColor} />} />
          <Route path="/loginmobile" element={<LoginMobile />} />
          <Route path="/useraccount" element={<UserAccount />} />
          <Route path="/messagebox" element={<MessageBox />} />
          <Route path="/*" element={<ErrorPage/>}/>
        </Routes>
      </div>
    </>
  );
}


const AppRouter = () => {
  return (
    <BrowserRouter>
  
      <App />
    
    </BrowserRouter>
  );
};

export default AppRouter;
