import React, { useEffect, useState } from "react";
import Header2 from "../Header_next";
import { useNavigate } from "react-router-dom";
import FloatingLabelInput from "../Reusable/FloatingLabelInput";
import axios from "axios";
import { toast } from "react-toastify";

const LoginMobile = () => {
   const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(process.env.REACT_APP_API_URL);
  const [loginDetails, setLoginDetails] = useState({
    phone_number: "",
    token: "09|GCzEhGrd1fohcwpr8xlX8Uy06fWHDYV5fvvcM7ZL17fbb59b",
  });
  const phoneRegex = /^[0-9]{10}$/;

  const navigate = useNavigate();

  useEffect(() => {
    if (window.sessionStorage.getItem("loggedUserid")) {
      window.sessionStorage.removeItem("loggedUserid");
    }
  }, []);

  const handleNavigation = async (e) => {
    e.preventDefault();

    if (
      loginDetails?.phone_number &&
      phoneRegex.test(loginDetails?.phone_number)
    ) {
      try {
        setIsLoading(true); // Set loading state to true
        const response = await axios({
          method: "post",
          url: `${url}/verify-login`,
          data: loginDetails,
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response?.data?.status == 200) {
          toast.success("Otp sent");

          // setTimeout(() => {
            navigate("/verifymobile", {
              state: {
                owner_id: response?.data?.owner_id,
                code_value: response?.data?.code_value,
                number: loginDetails?.phone_number,
                route:"loginpage"
              },
            });
          // },3000);
          // console.log('code_value:- ' +response?.data?.code_value);
         
        } else {
          setIsLoading(false); // Set loading state to true
          toast.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      toast.error("Invalid mobile number");
    }
  };

  const handleFormdata = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  return (
    <>
      <Header2 />
      <div className="main_div">
        <div className="login_mobile">
          <p>Login With Mobile</p>
          <div className="code_line"></div>
          <p className="para_4">
            A 4 digit verification code will be sent on your mobile
          </p>
        </div>

        <div className="input_box">
          <FloatingLabelInput
            id="phone_number"
            label="Enter Registered Mobile Number"
            type="number"
            name="phone_number"
            requiredval={1}
            value={loginDetails.number}
            handleFormdata={handleFormdata} />
        </div>

        <button
          className="submit_btn" disabled={isLoading}
          style={{ marginTop: "20px" }}
          onClick={(e) => handleNavigation(e)}>
          <a>{isLoading ? "Sending OTP Please Wait..." : "SUBMIT"}</a>
        </button>
      </div>
    </>
  );
};

export default LoginMobile;
